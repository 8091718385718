<template>
	<div class="pb-1">
		<b-card class="mb-1" no-body>
			<b-card-header class="pb-50">
				<div>
					<h3>Images</h3>
					<p>You can upload jpg, png, jpeg, gif, svg, webp - Max 2MB</p>
				</div>
			</b-card-header>
			<b-card-body>
				<b-row class="mt-5">
					<b-col cols="12" md="3" class="mb-md-0 mb-2">
						<h5>Login Image</h5>
						<p>This will also show on Forgot Password and Reset Password pages</p>
						<button
							v-if="Object.keys(languages).length > 0 && previews.login && !previews.login.includes('blob:')"
							class="btn btn-secondary"
							@click="
								modal.show = true;
								modal.type = 'login';
							">
							Add Translated Images
						</button>
					</b-col>
					<b-col cols="12" md="4" class="mb-md-0 mb-2">
						<div class="mb-2">
							<b-form-input v-model="urls.login" placeholder="URL" />
						</div>
						<div>
							<label for="loginimage" class="drop-container">
								<span class="drop-title">Drop files here</span>
								or
								<input type="file" id="loginimage" accept="image/*" @change="fileUploaded" ref="login" />
							</label>
						</div>
					</b-col>
					<b-col cols="12" md="5" class="mb-md-0 mb-2">
						<div class="d-flex flex-column align-items-center">
							<p class="text-center">Preview</p>
							<img width="500px" v-if="previews.login" :src="previews.login" />
						</div>
					</b-col>
				</b-row>
				<b-row class="mt-5">
					<b-col cols="12" md="3" class="mb-md-0 mb-2">
						<h5>Register Image</h5>
						<p>This will also show on Verify Email page</p>
						<button
							v-if="Object.keys(languages).length > 0 && previews.register && !previews.register.includes('blob:')"
							class="btn btn-secondary"
							@click="
								modal.show = true;
								modal.type = 'register';
							">
							Add Translated Images
						</button>
					</b-col>
					<b-col cols="12" md="4" class="mb-md-0 mb-2">
						<div class="mb-2">
							<b-form-input v-model="urls.register" placeholder="URL" />
						</div>
						<div>
							<label for="registerimage" class="drop-container">
								<span class="drop-title">Drop files here</span>
								or
								<input type="file" id="registerimage" accept="image/*" @change="fileUploaded" ref="register" />
							</label>
						</div>
					</b-col>
					<b-col cols="12" md="5" class="mb-md-0 mb-2">
						<div class="d-flex flex-column align-items-center">
							<p class="text-center">Preview</p>
							<img width="500px" v-if="previews.register" :src="previews.register" />
						</div>
					</b-col>
				</b-row>
				<b-row class="mt-5">
					<b-col cols="12">
						<button class="btn btn-primary btn-block" @click="save">Save</button>
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
		<b-modal v-model="modal.show" size="xl" title="Add Translated Images">
			<b-row>
				<b-col cols="12" md="6" xl="3" class="mb-md-0 mb-2" v-for="(language, code) in languages" :key="code" v-if="code !== 'en' && translated[code][modal.type]">
					<div class="mb-2">
						<h4>{{ language }}</h4>
					</div>
					<div class="mb-2">
						<b-form-input v-model="translated[code][modal.type].url" placeholder="URL" />
					</div>
					<div>
						<label :for="`${code}login`" class="drop-container">
							<span class="drop-title">Drop files here</span>
							or
							<input type="file" :id="`${code}login`" accept="image/*" @change="fileUploaded" :ref="`${code}login`" />
						</label>
					</div>
					<div class="d-flex flex-column align-items-center">
						<p class="text-center">Preview</p>
						<img width="100%" v-if="translated[code][modal.type].preview" :src="translated[code][modal.type].preview" />
					</div>
				</b-col>
			</b-row>
			<template #modal-footer>
				<div class="w-100 d-flex justify-content-between">
					<button class="btn btn-primary btn-block" @click="saveTranslated()">Save and Close</button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { BModal, BCard, BCardHeader, BCardBody, BFormInput, BInputGroup, BInputGroupPrepend, BRow, BCol, BButton, BBadge } from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

export default {
	components: {
		BModal,
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardBody,
		BFormInput,
		BInputGroup,
		BInputGroupPrepend,
		BButton,
		BBadge,
	},
	directives: {
		Ripple,
	},
	data() {
		return {
			login: null,
			register: null,
			languages: [],
			translated: {},
			urls: {
				login: null,
				register: null,
			},
			previews: {
				login: null,
				register: null,
			},
			modal: {
				show: false,
				type: "",
			},
		};
	},
	mounted() {
		this.$http.get("/settings/auth/images").then((res) => {
			if (res.data) {
				if (res.data.auth && res.data.auth.login) {
					this.urls.login = res.data.auth.login.url;
					if (res.data.auth.login.image) {
						this.previews.login = res.data.url + "/" + res.data.auth.login.image;
					}
				}
				if (res.data.auth && res.data.auth.register) {
					this.urls.register = res.data.auth.register.url;
					if (res.data.auth.register.image) {
						this.previews.register = res.data.url + "/" + res.data.auth.register.image;
					}
				}
				if (res.data.languages) {
					this.languages = res.data.languages;
					Object.keys(this.languages).forEach((code) => {
						if (code !== "en") {
							this.translated[code] = {
								login: {
									url: this.fetchTranslatedField(res.data.url, res.data.auth.translated, code, "login", "url"),
									image: "",
									preview: this.fetchTranslatedField(res.data.url, res.data.auth.translated, code, "login", "image"),
								},
								register: {
									url: this.fetchTranslatedField(res.data.url, res.data.auth.translated, code, "register", "url"),
									image: "",
									preview: this.fetchTranslatedField(res.data.url, res.data.auth.translated, code, "register", "image"),
								},
							};
						}
					});
				}
			}
		});
	},
	methods: {
		fetchTranslatedField(url, translated, lang, type, field) {
			if (translated && translated[lang] && translated[lang][type] && translated[lang][type][field]) {
				if (field == "image") {
					return url + "/" + translated[lang][type][field];
				} else {
					return translated[lang][type][field];
				}
			} else {
				return "";
			}
		},
		fileUploaded() {
			if (this.$refs.login.files.length > 0) {
				this.login = this.$refs.login.files[0];
				this.previews.login = URL.createObjectURL(this.$refs.login.files[0]);
			}
			if (this.$refs.register.files.length > 0) {
				this.register = this.$refs.register.files[0];
				this.previews.register = URL.createObjectURL(this.$refs.register.files[0]);
			}
			Object.keys(this.languages).forEach((code) => {
				if (code !== "en") {
					if (this.$refs[`${code}login`] && this.$refs[`${code}login`][0].files.length > 0) {
						this.translated[code].login.image = this.$refs[`${code}login`][0].files[0];
						this.translated[code].login.preview = URL.createObjectURL(this.$refs[`${code}login`][0].files[0]);
					}
					if (this.$refs[`${code}register`] && this.$refs[`${code}register`][0].files.length > 0) {
						this.translated[code].register.image = this.$refs[`${code}register`][0].files[0];
						this.translated[code].register.preview = URL.createObjectURL(this.$refs[`${code}register`][0].files[0]);
					}
					this.$forceUpdate();
				}
			});
		},
		saveTranslated() {
			this.save();
			this.modal.show = false;
			this.modal.type = "";
		},
		save() {
			const formData = new FormData();
			if (this.login) {
				formData.append("login_image", this.login);
			}
			if (this.register) {
				formData.append("register_image", this.register);
			}
			Object.keys(this.languages).forEach((code) => {
				if (code !== "en") {
					if (this.translated[code].login.image) {
						formData.append(`translated_${code}_login_image`, this.translated[code].login.image);
					}
					if (this.translated[code].login.url) {
						formData.append(`translated_${code}_login_url`, this.translated[code].login.url);
					}
					if (this.translated[code].register.image) {
						formData.append(`translated_${code}_register_image`, this.translated[code].register.image);
					}
					if (this.translated[code].register.url) {
						formData.append(`translated_${code}_register_url`, this.translated[code].register.url);
					}
				}
			});
			formData.append("login_url", this.urls.login);
			formData.append("register_url", this.urls.register);
			const headers = { "Content-Type": "multipart/form-data" };
			this.$http.post("/settings/auth/images", formData, { headers }).then((res) => {
				if (res.status == 200) {
					this.$bvToast.toast("Images saved successfully!", {
						title: `Success`,
						variant: "success",
						solid: true,
					});
				}
			});
		},
	},
};
</script>

<style scoped>
.drop-container {
	position: relative;
	display: flex;
	gap: 10px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 200px;
	padding: 20px;
	border-radius: 10px;
	border: 2px dashed #555;
	color: #444;
	cursor: pointer;
	transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}

.drop-container:hover {
	background: #d3d1eb;
	border-color: #111;
}

.drop-container:hover .drop-title {
	color: #222;
}

.drop-title {
	color: #444;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	transition: color 0.2s ease-in-out;
}

input[type="file"] {
	width: 350px;
	max-width: 100%;
	color: #444;
	padding: 5px;
	background: #fff;
	border-radius: 10px;
	border: 1px solid #555;
}

input[type="file"]::file-selector-button {
	margin-right: 20px;
	border: none;
	background: #7d71f5;
	padding: 10px 20px;
	border-radius: 10px;
	color: #fff;
	cursor: pointer;
	transition: background 0.2s ease-in-out;
}

input[type="file"]::file-selector-button:hover {
	background: #7d71f5;
}
</style>
